import { useState, useRef, useEffect, useCallback } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { useGLTF, OrbitControls } from '@react-three/drei';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import { useNavigate } from 'react-router-dom';
import * as THREE from 'three';

const MainScene = ({ camera, orbitControlsRef }) => {
    const { scene } = useGLTF(`${process.env.PUBLIC_URL}/LandingPage.gltf`);
    const ref = useRef();
    const { gl } = useThree();
    const raycaster = useRef(new THREE.Raycaster());
    const [isAnimatingCamera, setIsAnimatingCamera] = useState(false);
    const targetCameraPosition = useRef(new THREE.Vector3());
    const animationSpeed = useRef(0.05);
    const [orbitMovement, setOrbitMovement] = useState(0);

    // Initial Camera Position and Target
    const initialCameraPosition = new THREE.Vector3(-0.15, 5.5, 4);
    const initialTargetPosition = new THREE.Vector3(-0.18, 3, 0);

    const navigate = useNavigate();

    useEffect(() => {
        scene.traverse((child) => {
            if (child.isMesh && child.material && child.material.isMeshStandardMaterial) {
                child.material.metalness = 0.5;
                child.material.roughness = 0.5;
            }
        });

        camera.position.copy(initialCameraPosition);
        camera.lookAt(initialTargetPosition);
        camera.fov = 62.73;
        camera.updateProjectionMatrix();
    }, [camera, scene]);

    const onCanvasClick = useCallback((event) => {
        const mouse = {
            x: (event.clientX / gl.domElement.clientWidth) * 2 - 1,
            y: -(event.clientY / gl.domElement.clientHeight) * 2 + 1,
        };

        raycaster.current.setFromCamera(mouse, camera);
        const intersects = raycaster.current.intersectObjects(scene.children, true);

        if (intersects.length > 0) {
            const clicked = intersects[0].object;
            console.log("Clicked object:", clicked.name);

            if (clicked.name === 'LogoBtn') {
                setOrbitMovement(0);
                window.location.reload();
            } else if (clicked.name === 'PublicationBtn') {
                setOrbitMovement(0);
                targetCameraPosition.current.set(2, 12, 0);
                orbitControlsRef.current.target.set(-10, 12, 0);
                setIsAnimatingCamera(true);
            } else if (clicked.name === 'ResearchBtn') {
                setOrbitMovement(0);
                targetCameraPosition.current.set(0, 12, 0);
                orbitControlsRef.current.target.set(10, 12, 0);
                setIsAnimatingCamera(true);
            } else if (clicked.name === 'GoBackBtn1' || clicked.name === 'GoBackBtn2') {
                setOrbitMovement(0);
                targetCameraPosition.current.copy(initialCameraPosition);
                orbitControlsRef.current.target.copy(initialTargetPosition);
                setIsAnimatingCamera(true);
            } else if (clicked.name === 'CVBtn') {
                setOrbitMovement(0);
                window.open('https://drive.google.com/file/d/1PqMgmlR6JFMNE8VP001hIVTZVpAgsEvi/view?usp=sharing');
            } else if (clicked.name === 'LinkedIn') {
                setOrbitMovement(0);
                window.open('https://www.linkedin.com/in/changsoo-jung-a83340108/');
            } else if (clicked.name === 'Github') {
                setOrbitMovement(0);
                window.open('https://github.com/cjung5');
            } else if (clicked.name === 'OrbitBtn') {
                targetCameraPosition.current.set(-0.15, 7.5, 8);
                orbitControlsRef.current.target.set(-0.17, 3, 0);
                setOrbitMovement(0.001);
                setIsAnimatingCamera(true);
            } else if (clicked.name === 'Research1Btn') {
                navigate('/research/egocentric-pose-estimation');
            } else if (clicked.name === 'Research2Btn') {
                navigate('/research/microgesture');
            } else if (clicked.name === 'Research3Btn') {
                navigate('/research/smoke-opacity');
            } else if (clicked.name === 'Research4Btn') {
                navigate('/research/smoke-detection');
            } else if (clicked.name === 'Research5Btn') {
                navigate('/research/csu101');
            } else if (clicked.name === 'MyRoomBtn') {
                navigate('/myroom');
            }
        }
    }, [camera, gl.domElement, scene.children, orbitControlsRef]);

    useEffect(() => {
        gl.domElement.addEventListener('click', onCanvasClick);
        return () => {
            gl.domElement.removeEventListener('click', onCanvasClick);
        };
    }, [onCanvasClick, gl.domElement]);

    useFrame(() => {
        if (isAnimatingCamera) {
            camera.position.lerp(targetCameraPosition.current, animationSpeed.current);
            orbitControlsRef.current.update();
            if (camera.position.distanceTo(targetCameraPosition.current) < 0.1) {
                camera.position.copy(targetCameraPosition.current);
                setIsAnimatingCamera(false);
            }
        }
        if (ref.current) {
            ref.current.rotation.y += orbitMovement;
        }
    });

    return <primitive object={scene} ref={ref} />;
};

const CameraController = ({ camera }) => {
    const isDragging = useRef(false);
    const lastMousePosition = useRef({ x: 0, y: 0 });

    const onMouseDown = useCallback((event) => {
        if (event.button === 0) {
            console.log("Mouse Down - Disabling OrbitControls");
            isDragging.current = true;
            lastMousePosition.current = { x: event.clientX, y: event.clientY };
        }
    }, []);

    const onMouseUp = useCallback(() => {
        isDragging.current = false;
    }, []);

    const onMouseMove = useCallback((event) => {
        if (isDragging.current) {
            const deltaX = event.clientX - lastMousePosition.current.x;
            const deltaY = event.clientY - lastMousePosition.current.y;

            const rotationSpeed = 0.005;
            camera.rotation.y -= deltaX * rotationSpeed;
            camera.rotation.x -= deltaY * rotationSpeed;

            lastMousePosition.current = { x: event.clientX, y: event.clientY };
        }
    }, [camera]);

    useEffect(() => {
        window.addEventListener('mousedown', onMouseDown);
        window.addEventListener('mouseup', onMouseUp);
        window.addEventListener('mousemove', onMouseMove);

        return () => {
            window.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('mouseup', onMouseUp);
            window.removeEventListener('mousemove', onMouseMove);
        };
    }, [onMouseDown, onMouseUp, onMouseMove]);

    return null;
};

const SceneWithControls = () => {
    const { camera } = useThree();
    const orbitControlsRef = useRef();

    return (
        <>
            <MainScene camera={camera} orbitControlsRef={orbitControlsRef} />
            <CameraController camera={camera} />
            <OrbitControls
                ref={orbitControlsRef}
                camera={camera}
                enableZoom={false}
                target={[-0.18, 3, 0]}
            />
        </>
    );
};

const LandingPage = () => (
    <Canvas
        style={{ width: '100vw', height: '100vh' }}
        shadows
        gl={(canvas) => {
            canvas.toneMapping = THREE.ACESFilmicToneMapping;
            canvas.outputEncoding = THREE.sRGBEncoding;
        }}
    >
        <SceneWithControls />
        <EffectComposer>
            <Bloom luminanceThreshold={0.4} luminanceSmoothing={0.9} intensity={1.5} />
        </EffectComposer>
    </Canvas>
);

export default LandingPage;