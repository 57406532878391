import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { useGLTF, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import { useNavigate } from 'react-router-dom';
import './MyRoom.css';

// NEW: import the MusicPlayer component
import MusicPlayer from '../MusicPlayer/MusicPlayer';

//
// 1) Loads your GLTF scene
//
const MyRoomScene = () => {
  const { scene } = useGLTF(`${process.env.PUBLIC_URL}/myroom.gltf`);
  const ref = useRef();

  useEffect(() => {
    scene.traverse((child) => {
      if (child.isMesh && child.material) {
        child.material.metalness = 0.5;
        child.material.roughness = 0.5;
      }
    });
  }, [scene]);

  return <primitive object={scene} ref={ref} />;
};

//
// 2) DebugCamera: Logs the camera's position and direction every frame
//
const DebugCamera = () => {
  const { camera } = useThree();

  useFrame(() => {
    const direction = new THREE.Vector3();
    camera.getWorldDirection(direction);

    console.log(
      `Camera pos: [${camera.position.x.toFixed(2)}, ${camera.position.y.toFixed(2)}, ${camera.position.z.toFixed(2)}], direction:`,
      direction
    );
  });

  return null; // No visual output
};

//
// 3) AdjustInitialCamera: Set initial camera position and direction
//
const AdjustInitialCamera = () => {
  const { camera } = useThree();

  useEffect(() => {
    const initialPosition = new THREE.Vector3(2, 2, 3); // Abstract view's position
    const desiredDirection = new THREE.Vector3(2, 2, 0); // Abstract view's target
    const lookPoint = desiredDirection; // Target point for lookAt

    camera.position.copy(initialPosition);
    camera.lookAt(lookPoint);
    camera.updateProjectionMatrix();

    console.log('Initial camera position and direction set:', {
      position: camera.position,
      direction: desiredDirection,
    });
  }, [camera]);

  return null;
};

//
// 4) CameraController: Allows switching between views
//
const CameraController = ({ currentView, isUserDragging, orbitRef }) => {
  const { camera } = useThree();

  const views = {
    Overview: {
      pos: new THREE.Vector3(3.5, 5, -3),
      look: new THREE.Vector3(-1, 3, 0),
    },
    Desk: {
      pos: new THREE.Vector3(0.3, 4, -1),
      look: new THREE.Vector3(-3, 2, -2.7),
    },
    Couch: {
      pos: new THREE.Vector3(3.5, 3, 2),
      look: new THREE.Vector3(0, 3, 0),
    },
    Window: {
      pos: new THREE.Vector3(1.5, 5, -3),
      look: new THREE.Vector3(1, 4, 0),
    },
  };

  useEffect(() => {
    if (!orbitRef || !orbitRef.current || isUserDragging) return;

    const { pos, look } = views[currentView] || views.Abstract;
    camera.position.copy(pos);

    if (look) {
      camera.lookAt(look);
      orbitRef.current.target.copy(look);
      orbitRef.current.update(); // Ensure OrbitControls reflects the new target
    }

    console.log(`Switched to view: ${currentView}`);
  }, [currentView, isUserDragging, camera, orbitRef]);

  return null;
};

//
// 5) Main Page Component
//
const MyRoom = () => {
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState('Overview'); // Default initial view
  const [isUserDragging, setIsUserDragging] = useState(false);
  const orbitRef = useRef(null);

  const handleViewChange = (view) => {
    setIsUserDragging(false);
    setCurrentView(view);
  };

  return (
    <div className="myroom-container">
      <Canvas
        style={{ width: '100vw', height: '100vh' }}
        shadows
        camera={{
          position: [2, 2, 3], // Abstract view's initial position
          fov: 50,
        }}
        gl={(canvas) => {
          canvas.toneMapping = THREE.ACESFilmicToneMapping;
          canvas.outputEncoding = THREE.sRGBEncoding;
        }}
      >
        <MyRoomScene />

        {/* Adjust initial camera position and direction */}
        <AdjustInitialCamera />

        {/* OrbitControls: Enables user interaction */}
        <OrbitControls
          ref={orbitRef}
          target={[2, 2, 0]} // Abstract view's initial target
          onPointerDown={() => setIsUserDragging(true)}
          onPointerUp={() => setIsUserDragging(false)}
        />

        {/* Handle view switching */}
        <CameraController
          currentView={currentView}
          isUserDragging={isUserDragging}
          orbitRef={orbitRef}
        />

        {/* Debug camera for logging */}
        {/* <DebugCamera /> */}
      </Canvas>

      {/* Floating buttons for switching views */}
      <div className="floating-buttons">
        {['Overview', 'Desk', 'Couch', 'Window'].map((view) => (
          <button
            key={view}
            className={`floating-button ${currentView === view ? 'active' : ''}`}
            onClick={() => handleViewChange(view)}
          >
            {view}
          </button>
        ))}
      </div>

      {/* Go back button */}
      <button className="go-back-button" onClick={() => navigate(-1)}>
        Go Back
      </button>

      {/* NEW: Add our floating MusicPlayer at the bottom center */}
      <MusicPlayer
        playlist={[
            { src: '/music/RainyDays.mp3', title: 'Rainy Days' },
            { src: '/music/Fume.mp3', title: 'Fume' },
            { src: '/music/Passions.mp3', title: 'Passions' },
            { src: '/music/Together.mp3', title: 'Together' },
            { src: '/music/CSU101.mp3', title: 'CSU101' },
            { src: '/music/Coffee.mp3', title: 'Coffee' },
            { src: '/music/Midnight.mp3', title: 'Midnight' },
            { src: '/music/Colors.mp3', title: 'Colors' },
            { src: '/music/Gesture.mp3', title: 'Gesture' },
            { src: '/music/Haze.mp3', title: 'Haze' },
        ]}
      />
    </div>
  );
};

export default MyRoom;