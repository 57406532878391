import React, { useEffect, useRef, useState } from 'react';
import './MusicPlayer.css';

// MUI
import { IconButton, Typography } from '@mui/material';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';

const MusicPlayer = ({ playlist }) => {
  const audioRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  /**
   * A) On mount, attempt autoplay (if browser permits)
   */
  useEffect(() => {
    console.log('MusicPlayer mounted -> autostart playback');
    setIsPlaying(true); // triggers the play effect below
  }, []);

  /**
   * B) Whenever currentIndex changes, set audio.src and optionally play
   */
  useEffect(() => {
    if (!audioRef.current) return;

    audioRef.current.src = playlist[currentIndex].src;
    console.log(
      `Switched to track index=${currentIndex}, src=${playlist[currentIndex].src}`
    );

    // Reset time display
    setCurrentTime(0);
    setDuration(0);

    // If currently "playing," start immediately
    if (isPlaying) {
      audioRef.current
        .play()
        .catch((err) => console.error('Error calling play():', err));
    }
  }, [currentIndex]);

  /**
   * C) Play/pause logic (triggered by isPlaying changes)
   */
  useEffect(() => {
    if (!audioRef.current) return;
    if (isPlaying) {
      audioRef.current
        .play()
        .catch((err) => console.error('Error calling play():', err));
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  /**
   * D) Update current time / duration
   */
  const handleTimeUpdate = () => {
    if (!audioRef.current) return;
    setCurrentTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration || 0);
  };

  /**
   * E) Next / Prev track
   */
  const handleNext = () => {
    console.log('Clicked NEXT button');
    setCurrentIndex((prev) => (prev + 1) % playlist.length);
    setIsPlaying(true);
  };

  const handlePrev = () => {
    console.log('Clicked PREV button');
    setCurrentIndex((prev) =>
      prev === 0 ? playlist.length - 1 : prev - 1
    );
    setIsPlaying(true);
  };

  /**
   * F) Format time as M:SS
   */
  const formatTime = (secs) => {
    if (isNaN(secs)) return '0:00';
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  /**
   * G) Toggle play/pause button
   */
  const togglePlay = () => {
    console.log('Clicked PLAY/PAUSE button');
    setIsPlaying((prev) => !prev);
  };

  /**
   * H) Automatically go to next track on ended
   */
  const handleEnded = () => {
    console.log('Track ended -> skip to next');
    handleNext();
  };

  return (
    <div className="music-player-wrapper">
      <div className="music-player-container">
        {/* 
          One row for:
          Title | curtime/totaltime | prev | play/pause | next
        */}
        <div className="music-player-row">
          {/* Song Title */}
          <Typography variant="h6" className="song-title">
            {playlist[currentIndex]?.title || 'No Title'}
          </Typography>


          <span className="spacer" />
          <span className="spacer" />
          <span className="spacer" />
          {/* Time Display */}
          <Typography variant="subtitle1" className="time-display">
            {formatTime(currentTime)} / {formatTime(duration)}
          </Typography>
          <span className="spacer" />
          <span className="spacer" />
          <span className="spacer" />

          {/* Prev Button */}
          <IconButton onClick={handlePrev} sx={{ color: '#fff' }}>
            <SkipPreviousIcon />
          </IconButton>

          {/* Play/Pause Button */}
          <IconButton onClick={togglePlay} sx={{ color: '#fff' }}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>

          {/* Next Button */}
          <IconButton onClick={handleNext} sx={{ color: '#fff' }}>
            <SkipNextIcon />
          </IconButton>
        </div>

        {/* Additional text below the row */}
        <Typography
          variant="caption"
          className="music-player-footer"
        >
          All songs are prompts based AI music by SUNOv4
        </Typography>

        {/* Hidden audio element */}
        <audio
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          onEnded={handleEnded}
        />
      </div>
    </div>
  );
};

export default MusicPlayer;