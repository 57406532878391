import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import EgocentricPoseEst from './research/EgocentricPoseEst';
import Microgesture from './research/Microgesture';
import SmokeOpacity from './research/SmokeOpacity';
import SmokeDetection from './research/SmokeDetection';
import CSU101 from './research/CSU101';
import MyRoom from './MyRoom/MyRoom';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/research/egocentric-pose-estimation" element={<EgocentricPoseEst />} />
                    <Route path="/research/microgesture" element={<Microgesture />} />
                    <Route path="/research/smoke-opacity" element={<SmokeOpacity />} />
                    <Route path="/research/smoke-detection" element={<SmokeDetection />} />
                    <Route path="/research/csu101" element={<CSU101 />} />
                    <Route path="/myroom" element={<MyRoom />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;